import { Component } from '@angular/core';

@Component({
  selector: 'app-edit-lti-contacts',
  templateUrl: './edit-lti-contacts.component.html',
  styleUrl: './edit-lti-contacts.component.scss'
})
export class EditLtiContactsComponent {

}
