import { Component } from '@angular/core';

@Component({
  selector: 'app-edit-lti-inventory',
  templateUrl: './edit-lti-inventory.component.html',
  styleUrl: './edit-lti-inventory.component.scss'
})
export class EditLtiInventoryComponent {

}
