import { Component, HostListener, Input, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { MdbModalRef, MdbModalService } from 'mdb-angular-ui-kit/modal';
import { Subscription } from 'rxjs';
import { ConfirmActionComponent } from 'src/app/components/modals/confirm-action/confirm-action.component';
import { BackendService } from 'src/app/services/backend.service';
import { EnvironmentService } from 'src/app/services/environment.service';

@Component({
  selector: 'app-point-of-sale',
  templateUrl: './point-of-sale.component.html',
  styleUrls: ['./point-of-sale.component.scss']
})
export class PointOfSaleComponent implements OnInit {
  @Input() communityDetails
  @Input() terminalId
  receiptPrinterId = '9f99161f163cee945aefaafdb699eb2e'
  displayMobilePOS: boolean = true
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.detectWidthChanges(window.innerWidth)
  }
  terminalSubscription: Subscription
  receiptPrinterSubscription: Subscription
  terminalStatus
  receiptPrinterStatus
  lineItems: any[] = []
  // showView: string = 'point_of_sale'
  showView: string = 'cash_reconciliation'
  confirmActionModalRef: MdbModalRef<ConfirmActionComponent>;

  constructor(
    public modalRef: MdbModalRef<PointOfSaleComponent>,
    private db: AngularFirestore,
    private modalService: MdbModalService,
    private backendService: BackendService
    ) { }

  ngOnInit() {
    console.log(this.terminalId)
    this.detectWidthChanges(window.innerWidth)
    this.listenToReaderChanges()
    this.listenToReceiptPrinterChanges()
    this.clearReaderDisplay()
  }

  startCashReconciliation() {
    this.setView('cash_reconciliation')
  }

  setView(view: string) {
    this.showView = view
  }

  exitPos() {
    let content = `<div class="text-center">Are you sure you want to close the Point of Sale?</p></div>`;
    this.confirmActionModalRef = this.modalService.open(ConfirmActionComponent, {
      containerClass: 'top',
      modalClass: 'modal-dialog-scrollable modal-central modal-md',
      ignoreBackdropClick: true,
      data: {
        title: 'Exit Point of Sale',
        content: content,
        confirmButton: 'Exit',
        confirmButtonPlural: 'Exiting',
        type: 'exitPOS'
      }
    })
    this.confirmActionModalRef.onClose.subscribe((message: any) => {
      if (message === 'exited') {
        this.modalRef.close()
      }
    });
  }



  
  listenToReaderChanges() {
    const readerRef = this.db.collection('cardReaderStates').doc(this.terminalId).valueChanges()
    this.terminalSubscription = readerRef.subscribe(async (res: any) => {
      // console.log(res)
      this.terminalStatus = res
    })
  }
  listenToReceiptPrinterChanges() {
    const printerRef = this.db.collection('receiptPrinters').doc(this.receiptPrinterId).valueChanges()
    this.receiptPrinterSubscription = printerRef.subscribe(async (res: any) => {
      // console.log(res)
      this.receiptPrinterStatus = res

    })
  }
  
  ngOnDestroy() {
    this.terminalSubscription.unsubscribe()
    this.receiptPrinterSubscription.unsubscribe()
    this.clearReaderDisplay()
  }


  detectWidthChanges(screenWidth: number) {
    this.displayMobilePOS = (screenWidth <= 900)
  }

  outputAddLineItem($event) {
    console.log($event)
    this.lineItems.push($event)
    this.setReaderDisplay()
  }

  outputEmptyCart($event) {
    this.lineItems = []
    this.clearReaderDisplay()
  }

  
  setReaderDisplay() {
    console.log(this.lineItems)
    this.backendService.setCommunityTerminalDisplay(this.communityDetails.id, this.terminalId, {lineItems: this.lineItems}).subscribe({
      next: async (res) => {
        console.log(res)
      },
      error: (err) => {
        console.warn(err)
        return
      },
    });

  }

  clearReaderDisplay() {
    this.backendService.clearCommunityTerminalDisplay(this.communityDetails.id, this.terminalId, {}).subscribe({
      next: async (res) => {
        console.log(res)
      },
      error: (err) => {
        console.warn(err)
        return
      },
    });

  }


}
