import { Component, OnInit, Input } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { FormControl, FormGroup } from '@angular/forms';
import { MdbModalRef } from 'mdb-angular-ui-kit/modal';

@Component({
  selector: 'app-confirm-action',
  templateUrl: './confirm-action.component.html',
  styleUrls: ['./confirm-action.component.scss']
})
export class ConfirmActionComponent implements OnInit {
  confirmActionForm: FormGroup
  @Input() type: string = 'default'
  @Input() title: string;
  @Input() content: string;
  @Input() confirmButton: string = 'Confirm'
  @Input() confirmButtonPlural: string = 'Confirming'
  @Input() miscData: any
  @Input() confirmText: string = ''
  @Input() confirmTextLabel: string = 'Confirm name'
  confirming: boolean = false;
  error
  constructor(
    public modalRef: MdbModalRef<ConfirmActionComponent>,
    private functions: AngularFireFunctions
  ) { }

  ngOnInit(): void {
    this.confirmActionForm = new FormGroup({
      confirmText: new FormControl(''),
    });
  }

  delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  async confirm() {
    switch (this.type) {
      case 'refund':
        this.issueRefund()
        break;
      case 'removeCardReader':
        this.deleteCardReader()
        break;
      case 'deleteGroup':
        this.deleteGroup()
        break;
      case 'deleteEnsemble':
        this.deleteEnsemble()
        break;
      case 'deleteTaxProfile':
        this.deleteTaxProfile()
        break;
      case 'deleteVideoPrice':
        this.deleteVideoPrice()
        break;
      case 'approveMember':
        this.handleMemberDecision('approve')
        break;
      case 'denyMember':
        this.handleMemberDecision('deny')
        break;
      case 'removeMember':
        this.removeExistingMember()
        break;
      case 'deleteCommunity':
        this.deleteCommunity()
        break;
        break;
      case 'deletePaymentMethod':
        this.deletePaymentMethod()
        break;
      case 'blockMember':
        this.handleMemberDecision('block')
        break;
        break;
      case 'blockExistingMember':
        this.blockExistingMember()
        break;
      case 'exitPOS':
        this.modalRef.close('exited')
        break;
      default:
        this.waitThenClose()
        break;
    }
  }

  async waitThenClose() {
    this.confirming = true;
    await this.delay(500);
    const closeMessage = 'confirm';
    this.modalRef.close(closeMessage)
  }

  handleMemberDecision(decision) {
    this.confirming = true;
    const communityId = this.miscData.communityId
    const id = this.miscData.id
    const callable = this.functions.httpsCallable('handleCommunityMemberDecision');
    const obs = callable({
      decision: decision,
      id: id,
      communityId: communityId
    });
    obs.subscribe({
      next: (res) => {
        this.modalRef.close('handled')
      },
      error: (err) => {
        this.confirming = false;
        this.error = err.message
      },
    })
  }

  removeExistingMember() {
    this.confirming = true;
    const communityId = this.miscData.communityId
    const id = this.miscData.id
    const callable = this.functions.httpsCallable('removeCommunityMember');
    const obs = callable({
      id: id,
      communityId: communityId
    });
    obs.subscribe({
      next: (res) => {
        this.modalRef.close('removed')
      },
      error: (err) => {
        this.confirming = false;
        this.error = err.message
      },
    })
  }

  deleteCommunity() {
    this.confirming = true;
    const communityId = this.miscData.communityId
    const callable = this.functions.httpsCallable('deleteCommunity');
    const obs = callable({
      communityId: communityId
    });
    obs.subscribe({
      next: (res) => {
        this.modalRef.close('removed')
      },
      error: (err) => {
        this.confirming = false;
        this.error = err.message
      },
    })
  }

  deletePaymentMethod() {
    this.confirming = true;
    const paymentMethodId = this.miscData.paymentMethodId
    const callable = this.functions.httpsCallable('deletePaymentMethod');
    const obs = callable({
      id: paymentMethodId
    });
    obs.subscribe({
      next: (res) => {
        this.modalRef.close('removed')
      },
      error: (err) => {
        this.confirming = false;
        this.error = err.message
      },
    })
  }

  blockExistingMember() {
    this.confirming = true;
    const communityId = this.miscData.communityId
    const id = this.miscData.id
    const callable = this.functions.httpsCallable('blockCommunityMember');
    const obs = callable({
      id: id,
      communityId: communityId
    });
    obs.subscribe({
      next: (res) => {
        this.modalRef.close('blocked')
      },
      error: (err) => {
        this.confirming = false;
        this.error = err.message
      },
    })
  }

  issueRefund() {
    this.confirming = true;
    const communityId = this.miscData.communityId
    const chargeId = this.miscData.id

    const callable = this.functions.httpsCallable('issueRefund');
    const obs = callable({
      id: chargeId,
      communityId: communityId
    });
    obs.subscribe({
      next: (res) => {
        const closeMessage = 'refunded';
        this.modalRef.close(closeMessage)
      },
      error: (err) => {
        this.confirming = false;
        this.error = err.message
      },
    })
  }

  deleteCardReader() {
    this.confirming = true;
    const communityId = this.miscData.communityId
    const cardReaderId = this.miscData.id

    const callable = this.functions.httpsCallable('deleteCardReader');
    const obs = callable({
      id: cardReaderId,
      communityId: communityId
    });
    obs.subscribe({
      next: (res) => {
        const closeMessage = 'deleted';
        this.modalRef.close(closeMessage)
      },
      error: (err) => {
        this.confirming = false;
        this.error = err.message
      },
    })
  }

  deleteGroup() {
    this.confirming = true;
    const communityId = this.miscData.communityId
    const groupId = this.miscData.id

    const callable = this.functions.httpsCallable('deleteCommunityGroup');
    const obs = callable({
      id: groupId,
      communityId: communityId
    });
    obs.subscribe({
      next: (res) => {
        const closeMessage = 'deleted';
        this.modalRef.close(closeMessage)
      },
      error: (err) => {
        this.confirming = false;
        this.error = err.message
      },
    })
  }

  deleteEnsemble() {
    this.confirming = true;
    const communityId = this.miscData.communityId
    const groupId = this.miscData.id

    const callable = this.functions.httpsCallable('deleteCommunityEnsemble');
    const obs = callable({
      id: groupId,
      communityId: communityId
    });
    obs.subscribe({
      next: (res) => {
        const closeMessage = 'deleted';
        this.modalRef.close(closeMessage)
      },
      error: (err) => {
        this.confirming = false;
        this.error = err.message
      },
    })
  }

  deleteTaxProfile() {
    this.confirming = true;
    const communityId = this.miscData.communityId
    const taxProfileId = this.miscData.id
    const callable = this.functions.httpsCallable('deleteCommunityTaxProfile');
    const obs = callable({
      id: taxProfileId,
      communityId: communityId
    });
    obs.subscribe({
      next: (res) => {
        const closeMessage = 'deleted';
        this.modalRef.close(closeMessage)
      },
      error: (err) => {
        this.confirming = false;
        this.error = err.message
      },
    })
  }

  deleteVideoPrice() {
    this.confirming = true;
    const communityId = this.miscData.communityId
    const videoPriceId = this.miscData.priceId
    const videoProductId = this.miscData.productId
    const callable = this.functions.httpsCallable('deleteProduct');
    const obs = callable({
      priceId: videoPriceId,
      productId: videoProductId,
      communityId: communityId
    });
    obs.subscribe({
      next: (res) => {
        const closeMessage = 'deleted';
        this.modalRef.close(closeMessage)
      },
      error: (err) => {
        this.confirming = false;
        this.error = err.message
      },
    })
  }


  ngOnDestroy(): void { }
}
