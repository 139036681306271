import { Component } from '@angular/core';

@Component({
  selector: 'app-edit-lti-forms',
  templateUrl: './edit-lti-forms.component.html',
  styleUrl: './edit-lti-forms.component.scss'
})
export class EditLtiFormsComponent {

}
