<footer class="text-center text-lg-start text-muted" style="background-color: var(--footer)">
    <!-- <section class="d-flex justify-content-center justify-content-lg-between p-4 border-bottom"> -->
    <section class="d-flex justify-content-center justify-content-lg-between border-bottom">
      <div class="me-5 d-none d-lg-block">
      </div>
      <div>
        <!-- <a href="" class="me-4 text-reset">
          <i class="fab fa-facebook-f"></i>
        </a>
        <a href="" class="me-4 text-reset">
          <i class="fab fa-twitter"></i>
        </a>
        <a href="" class="me-4 text-reset">
          <i class="fab fa-instagram"></i>
        </a> -->
      </div>
    </section>
    <section class="">
      <div class="container text-center text-md-start mt-5">
        <div class="row mt-2">
          <!-- First card that will be hidden on mobile devices -->
          <div class="col-md-6 col-lg-8 col-xl-6 mx-auto mb-5 d-none d-md-block">
            <h6 class="text-uppercase fw-bold">
              <div class="text-center">

                <p>Powered by</p>
                  <app-logo [height]="30"></app-logo>
                  <div class="small text-muted">Version {{version}}</div>
              </div>

            </h6>
          </div>

        </div>
      </div>
    </section>
    <div class="text-center p-4" style="background-color: var(--footer-darker)">
      © 2025 Copyright
      <a class="text-reset fw-bold">Event Odyssey Inc.</a>
    </div>
  </footer>
  