import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BackendService } from 'src/app/services/backend.service';

@Component({
  selector: 'app-cash-reconciliation',
  templateUrl: './cash-reconciliation.component.html',
  styleUrls: ['./cash-reconciliation.component.scss'],
})
export class CashReconciliationComponent implements OnInit {
  @Input() communityDetails: any;
  @Input() terminalId: string;
  @Input() terminalStatus: string;
  submitting: boolean = false
  error
  currencyDetails = {
    name: 'United States Dollar',
    isoCode: 'USD',
    denominations: [
      { name: 'Penny', namePlural: 'Pennies', type: 'coin', label: '1¢', value: 1 },
      { name: 'Nickel', namePlural: 'Nickels', type: 'coin', label: '5¢', value: 5 },
      { name: 'Dime', namePlural: 'Dimes', type: 'coin', label: '10¢', value: 10 },
      { name: 'Quarter', namePlural: 'Quarters', type: 'coin', label: '25¢', value: 25 },
      { name: 'One Dollar Bill', namePlural: 'One Dollar Bills', type: 'bill', label: '$1', value: 100 },
      { name: 'Five Dollar Bill', namePlural: 'Five Dollar Bills', type: 'bill', label: '$5', value: 500 },
      { name: 'Ten Dollar Bill', namePlural: 'Ten Dollar Bills', type: 'bill', label: '$10', value: 1000 },
      { name: 'Twenty Dollar Bill', namePlural: 'Twenty Dollar Bills', type: 'bill', label: '$20', value: 2000 },
      { name: 'Fifty Dollar Bill', namePlural: 'Fifty Dollar Bills', type: 'bill', label: '$50', value: 5000 },
      { name: 'One Hundred Dollar Bill', namePlural: 'One Hundred Dollar Bills', type: 'bill', label: '$100', value: 10000 },
    ],
  };

  reconciliationForm: FormGroup;
  totalAmount = 0;
  selectedItem: string = ''
  selectedLabel: string = ''
  selectedItemAmount: number = 0
  groupedDenominations: { type: string; denominations: any[] }[] = [];

  constructor(
    private backendService: BackendService
  ) { }

  ngOnInit(): void {
    // Initialize the form controls
    this.reconciliationForm = new FormGroup(
      this.currencyDetails.denominations.reduce((controls, denomination) => {
        controls[denomination.name] = new FormControl(0, [Validators.min(0)]);
        return controls;
      }, {})
    );

    // Group denominations by type
    this.groupedDenominations = this.groupByType(this.currencyDetails.denominations);

    // Subscribe to form changes to calculate the total
    this.reconciliationForm.valueChanges.subscribe(() => this.calculateTotal());

    this.makeSelection(this.currencyDetails.denominations[0].name, this.currencyDetails.denominations[0].namePlural)
  }


  tab(): void {
    const currentIndex = this.currencyDetails.denominations.findIndex(
      (denomination) => denomination.name === this.selectedItem
    );
    const nextIndex = (currentIndex + 1) % this.currencyDetails.denominations.length;
    const nextItem = this.currencyDetails.denominations[nextIndex];
    this.makeSelection(nextItem.name, nextItem.namePlural);
  }

  makeSelection(id: any, label: any) {
    this.selectedItem = id
    this.selectedLabel = label
    this.selectedItemAmount = this.reconciliationForm.controls[id].value
  }

  submit() {
    const enteredDenominations = this.currencyDetails.denominations
      .filter((denomination) => this.reconciliationForm.controls[denomination.name].value > 0)
      .map((denomination) => ({
        ...denomination,
        quantity: this.reconciliationForm.controls[denomination.name].value,
        subtotal: this.reconciliationForm.controls[denomination.name].value * denomination.value,
      }));

    const groupedEnteredDenominations = Object.entries(
      enteredDenominations.reduce((group, item) => {
        if (!group[item.type]) {
          group[item.type] = [];
        }
        group[item.type].push(item);
        return group;
      }, {})
    ).map(([type, data]) => ({ name: type, data }));

    const totalAmount = enteredDenominations.reduce((sum, item) => sum + item.subtotal, 0);

    console.log('Grouped Entered Denominations:', groupedEnteredDenominations);
    console.log('Total Amount:', totalAmount / 100); // Convert to dollars for display

    this.submitting = true
    this.backendService.handleCommunityTerminalReconciliation(this.communityDetails.id, this.terminalId, { enteredAmounts: groupedEnteredDenominations, totalAmount: totalAmount, type: 'Register Close' }).subscribe({
      next: async (res) => {
        console.log(res)
        this.submitting = false
      },
      error: (err) => {
        console.warn(err)
        this.error = err.message
        return
      },
    });

  }


  // submit() {
  //   this.submitting = true
  //   this.backendService.handleCommunityTerminalReconciliation(this.communityDetails.id, this.terminalId, {}).subscribe({
  //     next: async (res) => {
  //       console.log(res)
  //       this.submitting = false
  //     },
  //     error: (err) => {
  //       console.warn(err)
  //       this.error = err.message
  //       return
  //     },
  //   });
  // }

  inputChange($event) {
    if (this.selectedItem) {
      this.reconciliationForm.get(this.selectedItem).setValue(Number($event));
    }
  }

  groupByType(denominations: any[]): { type: string; denominations: any[] }[] {
    const grouped = denominations.reduce((acc, denom) => {
      if (!acc[denom.type]) {
        acc[denom.type] = [];
      }
      acc[denom.type].push(denom);
      return acc;
    }, {});

    return Object.keys(grouped).map((type) => ({
      type,
      denominations: grouped[type],
    }));
  }

  hasNonZeroValues(): boolean {
    return this.groupedDenominations.some((group) =>
      this.hasNonZeroValuesInGroup(group)
    );
  }

  hasNonZeroValuesInGroup(group: { type: string; denominations: any[] }): boolean {
    return group.denominations.some(
      (denomination) =>
        this.reconciliationForm.controls[denomination.name].value > 0
    );
  }

  calculateTotal(): void {
    const formValues = this.reconciliationForm.value;
    this.totalAmount = this.currencyDetails.denominations.reduce((total, denomination) => {
      const quantity = formValues[denomination.name] || 0;
      return total + quantity * denomination.value;
    }, 0);
  }
}
