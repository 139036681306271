<ng-container *ngIf="eventDetails; else noDetails">
  <div #pdfLoaded id="pdfLoaded">
    <div *ngFor="let ticket of tickets; trackBy: trackByTicketId">
      <div class="mt-3 ms-4 me-4 mb-4">
        <div>
          <div class="d-flex">
            <div class="flex-fill">
              <p class="mb-0 pb-0">{{communityDetails.name}}</p>
              <h5>{{eventDetails.name}}</h5>
            </div>
            <div class="flex-fill">
              <div class="text-end">
                <img src="./assets/logo.png" height="30" alt="" />
                <div>support&#64;eventodyssey.com</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="ms-4 me-4">
        <div class="row">
          <div class="col-4">
            <div class="qrContainer text-center">
              <ngx-qrcode-styling class="qrCode" [config]="config" [data]="ticket.id"></ngx-qrcode-styling>
            </div>
          </div>
          <div class="col-8">
            <div class="small mt-2">Ticket Type</div>
            <h5>{{ticket.type}}</h5>
            <div class="row">
              <div class="col-6">
                <div class="small">Section</div>
                <h5>{{ticket.section}}</h5>
              </div>
              <div class="col-6 text-end">
                <div class="small">Row / Seat</div>
                <h5>{{ticket.row}} / {{ticket.seat}}</h5>
              </div>
            </div>
            <div class="small">Doors Open</div>
            <h5>{{eventDetails.doorsOpenTime | date:'fullDate'}} at {{eventDetails.doorsOpenTime | date:'shortTime'}}
            </h5>
            <div class="small">Event Start</div>
            <h5>{{eventDetails.startTime | date:'fullDate'}} at {{eventDetails.startTime | date:'shortTime'}}</h5>
          </div>
        </div>
        <div class="mt-2 row">
          <div class="small">Venue Details</div>
            <h5 class="mt-0 pt-0 mb-0 pb-0">{{venueDetails.name}}</h5>
            <p class="mt-0 pt-0 mb-0 pb-0"><i class="fas fa-map-marker-alt"></i> {{venueDetails.address}}</p>
            <p><i class="fas fa-comment"></i> {{venueDetails.notes}}</p>
        </div>
        <app-view-map [id]="'map-'+ticket.id"  height="200px" width="100%" [zoom]="15" [lat]="venueDetails.lat" [lng]="venueDetails.lng">
        </app-view-map>
      </div>
      <div class="mt-5 ms-4 me-4">
        <div>
          <div class="d-flex mt-4">
            <div class="flex-fill">
              <p class="mb-1" style="font-size: 0.875rem;">
                If you have any questions, please contact us at
                <a href="mailto:support@eventodyssey.com">support&#64;eventodyssey.com</a> or call us at
                <a href="tel:+13174830027">+1 317-483-0027</a>.
              </p>
            </div>
          </div>
        </div>
      </div>

      <!-- Adjusted footer with reduced padding and split lines -->
      <div class="mt-3 small ms-4 me-4" style="font-size: 0.75rem; padding-top: 0.5rem;">
        <div>
          © 2025 Copyright Event Odyssey Inc.
        </div>
      </div>
      <div class="page-break"></div>

    </div>


  </div>
</ng-container>
<ng-template #noDetails>
  <ng-container *ngIf="loading; else error">
    <app-loading></app-loading>
  </ng-container>
  <ng-template #error>
    <div class="text-center">
      Ticket Generation Failed!
      <br>
      Pleace contact support <a href="mailto:support@eventodyssey.com">support&#64;eventodyssey.com</a> for assistance.
    </div>
  </ng-template>
</ng-template>