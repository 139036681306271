import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { Subscription } from 'rxjs';
import { BackendService } from 'src/app/services/backend.service';

@Component({
  selector: 'app-pos-controls',
  templateUrl: './pos-controls.component.html',
  styleUrls: ['./pos-controls.component.scss']
})
export class PosControlsComponent implements OnInit {
  @Input() communityDetails
  @Input() terminalId: string
  purchasing: boolean = false
  @Input() lineItems
  error
  @Output() emptyCart = new EventEmitter<any>();


  constructor(
    private backendService: BackendService,
  ) { }

  ngOnInit(): void {
    console.log(this.lineItems)
  }

  clearCart() {
    this.emptyCart.emit(true)
  }

  setReaderDisplay() {
    console.log(this.lineItems)
    this.purchasing = true
    this.backendService.setCommunityTerminalDisplay(this.communityDetails.id, this.terminalId, {lineItems: this.lineItems}).subscribe({
      next: async (res) => {
        console.log(res)
        this.purchasing = false
      },
      error: (err) => {
        console.warn(err)
        this.error = err.message
        return
      },
    });

  }

  clearReaderDisplay() {
    this.purchasing = true
    this.backendService.clearCommunityTerminalDisplay(this.communityDetails.id, this.terminalId, {}).subscribe({
      next: async (res) => {
        console.log(res)
        this.purchasing = false
      },
      error: (err) => {
        console.warn(err)
        this.error = err.message
        return
      },
    });

  }

  processReaderPayment() {
    this.purchasing = true
    this.backendService.processCommunityTerminalPayment(this.communityDetails.id, this.terminalId, {lineItems: this.lineItems}).subscribe({
      next: async (res) => {
        console.log(res)
        this.purchasing = false
      },
      error: (err) => {
        console.log(err)
        this.error = err.message
        return
      },
    });

  }


}
