import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { MdbModalRef, MdbModalService } from 'mdb-angular-ui-kit/modal';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { MdbNotificationService, MdbNotificationRef, MdbNotificationConfig } from 'mdb-angular-ui-kit/notification';

import { EnvironmentService } from 'src/app/services/environment.service';
import { NotificationsService } from 'src/app/services/notifications.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-pos-navigation',
  templateUrl: './pos-navigation.component.html',
  styleUrls: ['./pos-navigation.component.scss']
})
export class PosNavigationComponent implements OnInit {
  @Input() sidenav
  @Input() terminalStatus
  @Input() receiptPrinterStatus
  intervalId: any;
  constructor(
    private modalService: MdbModalService,
    private afAuth: AngularFireAuth,
    private toastService: MdbNotificationService,
    private route: ActivatedRoute,
    private cdr: ChangeDetectorRef,
    private notificationsService: NotificationsService,
    ) {
      this.intervalId = setInterval(() => {
        this.cdr.markForCheck(); // Notify Angular to re-evaluate bindings
      }, 15000); // 15 seconds
    }


  ngOnInit(): void {

  }

  ngOnChanges() {

  }

  isRecent(lastSeenAt: number): boolean {
    
    const fifteenMinutesInMs = 5 * 60 * 1000; // 15 minutes in milliseconds
    const now = Date.now(); // Current timestamp in milliseconds
    return lastSeenAt >= now - fifteenMinutesInMs;
  }

  isRecentPrinterStatus(lastSeenAt: number): boolean {
    if(!lastSeenAt) { return false }
    const fifteenMinutesInMs = 5 * 60 * 1000; // 15 minutes in milliseconds
    const now = Date.now(); // Current timestamp in milliseconds
    return lastSeenAt >= ((now - fifteenMinutesInMs) / 1000);
  }
  
  ngOnDestroy(): void {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  }

}
