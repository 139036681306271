<app-pos-navigation [sidenav]="sidenav" [receiptPrinterStatus]="receiptPrinterStatus"
  [terminalStatus]="terminalStatus"></app-pos-navigation>
<mdb-sidenav-layout>
  <mdb-sidenav #sidenav="mdbSidenav" mode="over" scrollContainer="#scroll-container" [transitionDuration]="225">
    <div class="modal-body">
      <div class="h-100 d-flex flex-row justify-content-center align-items-center">
        <div class="align-self-start flex-fill">
          <app-logo class="ms-1" [height]="20"></app-logo>
        </div>


        <div class="align-self-center">
          <button type="button" class="btn-close" aria-label="Close" (click)="sidenav.toggle()"></button>
        </div>
      </div>
    </div>
    <ul class="sidenav-menu" id="scroll-container" mdbScrollbar>

      <ul id="container" mdbScrollbar class="sidenav-menu mb-4 noselect">
        <ng-container *ngIf="showView !== 'cash_reconciliation'; else displayReconciliationMenu">
          <mdb-sidenav-item>
            <a class="sidenav-link"  (click)="sidenav.toggle()" (click)="startCashReconciliation()" mdbWavesEffect>
              <i class="fas fa-money-bill fa-fw me-3"></i><span>Cash Reconciliation</span>
            </a>
          </mdb-sidenav-item>
        </ng-container>
        <ng-template #displayReconciliationMenu>
          <mdb-sidenav-item>
            <a class="sidenav-link" (click)="sidenav.toggle()" (click)="setView('point_of_sale')" mdbWavesEffect>
              <i class="fas fa-times fa-fw me-3"></i><span>Cancel Reconciliation</span>
            </a>
          </mdb-sidenav-item>
        </ng-template>
        <mdb-sidenav-item>
          <a class="sidenav-link" (click)="sidenav.toggle()" (click)="exitPos()" mdbWavesEffect>
            <i class="fas fa-door-closed fa-fw me-3"></i><span>Exit Point of Sale</span>
          </a>
        </mdb-sidenav-item>
      </ul>
      <!-- <div class="btn btn-rounded" (click)="modalRef.close()">Exit Point of Sale</div> -->
    </ul>
  </mdb-sidenav>
  <mdb-sidenav-content #sidenavContent>
    <ng-container *ngIf="displayMobilePOS; else displayMainView">
      <app-mobile-pos-view></app-mobile-pos-view>
    </ng-container>
    <ng-template #displayMainView>
      <div [ngSwitch]="showView">
        <div *ngSwitchCase="'point_of_sale'">
          <app-main-pos-view [communityDetails]="communityDetails" [terminalId]="terminalId"
          [terminalStatus]="terminalStatus" [receiptPrinterStatus]="receiptPrinterStatus" [lineItems]="lineItems" (outputAddLineItem)="outputAddLineItem($event)"
          (outputEmptyCart)="outputEmptyCart($event)"></app-main-pos-view>
        </div>
        <div *ngSwitchCase="'cash_reconciliation'">
          <app-cash-reconciliation [communityDetails]="communityDetails" [terminalId]="terminalId"
          [terminalStatus]="terminalStatus"></app-cash-reconciliation>
        </div>
      </div>

    </ng-template>
  </mdb-sidenav-content>
</mdb-sidenav-layout>
<em></em>