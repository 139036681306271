import { Component } from '@angular/core';

@Component({
  selector: 'app-edit-lti-fees',
  templateUrl: './edit-lti-fees.component.html',
  styleUrl: './edit-lti-fees.component.scss'
})
export class EditLtiFeesComponent {

}
