import { Component, Input, OnInit } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MdbModalRef } from 'mdb-angular-ui-kit/modal';
import { BackendService } from 'src/app/services/backend.service';

@Component({
  selector: 'app-edit-card-reader',
  templateUrl: './edit-card-reader.component.html',
  styleUrl: './edit-card-reader.component.scss'
})
export class EditCardReaderComponent {
  ticketThemes: any[] = []
  loadingGroups: boolean = false
  ticketThemesLoaded: boolean = false
  displaySelectDropdown: boolean = false

  eventForm: FormGroup
  @Input() communityDetails
  @Input() cardReaderDetails
  savingEvent: boolean = false
  editorStyle = {
    height: '100px'
  };
  config = {
    toolbar: [
      ['bold', 'italic', 'underline',],
      [{ 'color': [] }, { 'background': [] }],
      [{ 'align': [] }],
      [{ 'list': 'ordered' }, { 'list': 'bullet' }],
      [{ 'indent': '-1' }, { 'indent': '+1' }],
    ]
  };
  error
  constructor(
    public modalRef: MdbModalRef<EditCardReaderComponent>,
    private backendService: BackendService
  ) { }

  ngOnInit(): void {
    console.log(this.cardReaderDetails)
    this.eventForm = new FormGroup({
      label: new FormControl(this.cardReaderDetails.label, Validators.compose([Validators.minLength(3), Validators.required])),

    });
  }



  submit() {
    const label = this.eventForm.controls.label.value
    this.savingEvent = true
    this.backendService.updateCommunityCardReader(this.communityDetails.id, this.cardReaderDetails.id, {
      label: label
    }).subscribe({
      next: async (res) => {
        this.modalRef.close('updated')
        this.savingEvent = false
      },
      error: (err) => {
        console.warn(err)
        this.error = err.message
        return
      },
    });

  }

}
