import { 
  Component, 
  EventEmitter, 
  Input, 
  KeyValueDiffers, 
  OnInit, 
  Output 
} from '@angular/core';

@Component({
  selector: 'app-main-pos-view',
  templateUrl: './main-view.component.html',
  styleUrls: ['./main-view.component.scss']
})
export class MainPOSViewComponent implements OnInit {
  @Input() communityDetails;
  @Input() terminalId;
  @Input() terminalStatus;
  @Input() receiptPrinterStatus;
  @Input() lineItems: any[] = [];
  @Output() outputAddLineItem = new EventEmitter<any>();
  @Output() outputEmptyCart = new EventEmitter<any>();

  subtotal = 0;
  private differ: any;

  constructor(private differs: KeyValueDiffers) {
    this.differ = this.differs.find([]).create(); // Initialize the differ
  }

  ngOnInit(): void {
    this.updateSubtotal();
  }

  ngDoCheck(): void {
    const changes = this.differ.diff(this.lineItems);
    if (changes) {
      console.log('lineItems changed');
      this.updateSubtotal();
    }
  }

  private updateSubtotal(): void {
    this.subtotal = this.lineItems.reduce((sum, item) => sum + item.amount, 0);
  }

  addProduct(price: number): void {
    console.log('Adding product');
    const product = {
      amount: price,
      price: price,
      description: 'Sample Product',
      quantity: 1
    };
    this.outputAddLineItem.emit(product);

  }

  emptyCart($event) {
    this.outputEmptyCart.emit($event); 
  }
}
