<div class="container-fluid full-height p-3">
  <div class="row text-center">
    <div class="col-12 d-flex justify-content-center align-items-center card card-border">
      <div style="width: 100%">
        <form [formGroup]="inputForm">
          <div class="input-group mt-2">
            <div class="input-container">
              <mdb-form-control>
                <input mdbInput [mdbValidate] type="number" formControlName="input" id="input"
                  class="form-control form-control-lg bg-light" [validateSuccess]="false" placeholder=""
                  (focus)="onFocusInput($event)" (blur)="onBlurInput()" />
                <label mdbLabel class="form-label" for="input">{{label}}</label>
              </mdb-form-control>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
  <div class="row text-center">
    <div mdbRipple class="col-4 d-flex justify-content-center align-items-center" (click)="appendValue(7)">
      <div>7</div>
    </div>
    <div mdbRipple class="col-4 d-flex justify-content-center align-items-center" (click)="appendValue(8)">
      <div>8</div>
    </div>
    <div mdbRipple class="col-4 d-flex justify-content-center align-items-center" (click)="appendValue(9)">
      <div>9</div>
    </div>
  </div>
  <div class="row text-center">
    <div mdbRipple class="col-4 d-flex justify-content-center align-items-center" (click)="appendValue(4)">
      <div>4</div>
    </div>
    <div mdbRipple class="col-4 d-flex justify-content-center align-items-center" (click)="appendValue(5)">
      <div>5</div>
    </div>
    <div mdbRipple class="col-4 d-flex justify-content-center align-items-center" (click)="appendValue(6)">
      <div>6</div>
    </div>
  </div>
  <div class="row text-center">
    <div mdbRipple class="col-4 d-flex justify-content-center align-items-center" (click)="appendValue(1)">
      <div>1</div>
    </div>
    <div mdbRipple class="col-4 d-flex justify-content-center align-items-center" (click)="appendValue(2)">
      <div>2</div>
    </div>
    <div mdbRipple class="col-4 d-flex justify-content-center align-items-center" (click)="appendValue(3)">
      <div>3</div>
    </div>
  </div>
  <div class="row text-center">
    <div mdbRipple class="col-4 d-flex justify-content-center align-items-center" (click)="clearInput()">
      <div>C</div>
    </div>
    <div mdbRipple class="col-4 d-flex justify-content-center align-items-center" (click)="appendValue(0)">
      <div>0</div>
    </div>
  </div>
</div>