import { Component } from '@angular/core';

@Component({
  selector: 'app-view-lti-fees',
  templateUrl: './view-lti-fees.component.html',
  styleUrl: './view-lti-fees.component.scss'
})
export class ViewLtiFeesComponent {

}
