<!-- Navbar-->
<nav class="navbar sticky-top navbar-expand-lg text-light navbar-dark bg-dark noselect">
  <div class="container-fluid justify-content-between">
    <!-- Left elements -->
    <div class="d-flex">
      <!-- Mobile Nav -->
      <ul class="navbar-nav flex-row d-">
        <li class="nav-item me-3 me-lg-1 active">
          <a (click)="sidenav.toggle()" class="nav-link pointer">
            <span><i class="fas fa-bars fa-lg"></i></span>
          </a>
        </li>
      </ul>

      <ul class="navbar-nav flex-row">
        <li class="nav-item me-3 me-lg-1">
          <a class="nav-link d-sm-flex align-items-sm-center pointer">
            <span class="d-none d-sm-block ms-1">Point of Sale</span>
          </a>
        </li>
      </ul>


    </div>
    <!-- Left elements -->


    <!-- Right elements -->
    <ul class="navbar-nav flex-row">
      <li *ngIf="terminalStatus" class="nav-item me-3 me-lg-1">
        <a class="nav-link d-sm-flex align-items-sm-center pointer">
          <i 
          class="fas fa-circle" 
          [style.color]="isRecent(terminalStatus.last_seen_at) ? 'var(--mdb-success)' : 'var(--mdb-danger)'">
        </i>        
          <span class="d-none text-light d-sm-block ms-1">Card Terminal</span>
        </a>
      </li>
      <li *ngIf="receiptPrinterStatus" class="nav-item me-3 me-lg-1">
        <a class="nav-link d-sm-flex align-items-sm-center pointer">
          <i class="fas fa-circle" [style.color]="isRecentPrinterStatus(receiptPrinterStatus.lastSeen) ? 'var(--mdb-success)' : 'var(--mdb-danger)'"></i>
          <span class="d-none text-light d-sm-block ms-1">Receipt Printer</span>
        </a>
      </li>
    </ul>
    <!-- Right elements -->
  </div>
</nav>
<!-- Navbar -->