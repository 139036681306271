import { Component } from '@angular/core';

@Component({
  selector: 'app-view-lti-forms',
  templateUrl: './view-lti-forms.component.html',
  styleUrl: './view-lti-forms.component.scss'
})
export class ViewLtiFormsComponent {

}
