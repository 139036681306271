import { Component } from '@angular/core';

@Component({
  selector: 'app-view-lti-contacts',
  templateUrl: './view-lti-contacts.component.html',
  styleUrl: './view-lti-contacts.component.scss'
})
export class ViewLtiContactsComponent {

}
