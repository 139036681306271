import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-lti-footer',
  templateUrl: './lti-footer.component.html',
  styleUrl: './lti-footer.component.scss'
})
export class LtiFooterComponent implements OnInit {
  version: string = ''

  constructor(
    private httpClient: HttpClient
  ) {}

  ngOnInit(): void {
    this.checkVersion()
  }
  checkVersion() {
    this.httpClient.get('./assets/version.txt', {responseType: 'text'}).subscribe((data: any) => {
      this.version = data
  })}

}
