import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { ActivatedRoute, Router } from '@angular/router';
import { MdbModalRef, MdbModalService } from 'mdb-angular-ui-kit/modal';
import { Subscription } from 'rxjs';
import { LoginComponent } from 'src/app/components/shared/login/login.component';
import { BackendService } from 'src/app/services/backend.service';

@Component({
  selector: 'app-link-lti-account',
  templateUrl: './link-lti-account.component.html',
  styleUrls: ['./link-lti-account.component.scss']
})
export class LinkLtiAccountComponent implements OnInit {
  res
  error
  routeSub: Subscription;
  headerText: string = 'Loading...'
  token: string = ''
  loading: boolean = true
  userLoggedIn: boolean = false
  editRegistration: boolean = false
  modalRef: MdbModalRef<LoginComponent> | null = null;
  authSubscription: Subscription
  constructor(
    private activatedRoute: ActivatedRoute,
    private modalService: MdbModalService,
    private http: HttpClient,
    private router: Router,
    private afAuth: AngularFireAuth,
    private backendService: BackendService
  ) { }

  ngOnInit(): void {
    this.checkAuthState()
    console.log('lets go')
    this.extractTokenFromFragment()
  }

  checkAuthState() {
    this.authSubscription = this.afAuth.authState.subscribe((user) => {
      this.userLoggedIn = Boolean(user)
      console.log(this.userLoggedIn)
    })
  }

  saveRegistrationConfig($event) {
    this.loading = true
    this.editRegistration = false
    this.headerText = 'Saving Details...'
    this.backendService.saveLtiRegistration($event).subscribe({
      next: async (res) => {
        console.log(res)
        this.loadLTIConfig()
      },
      error: (err) => {
        this.headerText = 'Error'
        this.loading = false
        console.warn(err)
        this.error = err.message
        return
      },
    });
  }

  loadLTIConfig() {
    this.loading = true
    this.backendService.getLTIConfig().subscribe({
      next: async (res) => {
        this.res = res
        console.log(res)
        if (res.resourceConfigured) {
          this.headerText = res.resourceDetails.headerName
        } else {
          if (res.isTeacher) {
            this.headerText = 'Setup Resource'
          } else {
            this.headerText = 'Resource Not Setup'
          }
        }
        this.loading = false
      },
      error: (err) => {
        this.headerText = 'Error'
        this.loading = false
        console.warn(err)
        this.error = err.message
        return
      },
    });
  }

  async loginWithToken(token: string) {
    console.log('login with firebase')
    // Store JWT in memory - don't use firebase to login
    this.afAuth.setPersistence('local').then(() => {
      this.afAuth.signInWithCustomToken(token)
        .then(
          res => {
            this.loadLTIConfig()
          },
          err => {
            console.log(err)
            // this.errorToast(err.message);
          });
    });
  }


  extractTokenFromFragment(): void {
    this.routeSub = this.activatedRoute.queryParams.subscribe(async (params) => {
      const authToken = params['token'];
      if (authToken) {
        this.token = authToken
        // Save the token in local storage or any other secure storage
        localStorage.setItem('lti-firebase-auth-token', authToken);
        this.loginWithToken(authToken)
        // Navigate to your desired route
      } else {
        // Handle the case where the token is not present
        console.error('LTI Token not found in URL fragment');
      }
    });
  }
}
