import { Component, EventEmitter, HostListener, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-pos-keypad',
  templateUrl: './pos-keypad.component.html',
  styleUrls: ['./pos-keypad.component.scss']
})
export class PosKeypadComponent implements OnInit {
  @Input() label: string = 'Input';
  @Input() value: number = 0;
  @Output() inputChange = new EventEmitter<number>();
  @Output() tabOutput = new EventEmitter<any>();

  inputForm: FormGroup;
  shouldResetInput: boolean = true;
  disableKeyListener: boolean = false; // Tracks whether the global listener should be active

  constructor() {}

  ngOnInit() {
    this.inputForm = new FormGroup({
      input: new FormControl(this.value),
    });

    this.inputForm.get('input').valueChanges.subscribe((value: number) => {
      this.inputChange.emit(value);
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes) {
      this.inputForm.get('input').setValue(this.value);
      this.shouldResetInput = true;
    }
  }

  @HostListener('window:keydown', ['$event'])
  handleKeydown(event: KeyboardEvent): void {

    if (event.key >= '0' && event.key <= '9') {
      if (this.disableKeyListener) return; // Skip handling if disabled
      this.appendValue(Number(event.key));
      event.preventDefault();
    } else if (event.key === 'Tab') {
      this.tab();
      event.preventDefault();
    } else if (event.key === 'c' || event.key === 'C') {
      this.clearInput();
      event.preventDefault();
    }
  }

  appendValue(value: number) {
    let currentValue = this.inputForm.get('input').value.toString();
    if (this.shouldResetInput) {
      currentValue = '0';
    }
    this.shouldResetInput = false;
    const newValue = currentValue === '0' ? value.toString() : currentValue + value.toString();
    this.inputForm.get('input').setValue(Number(newValue));
  }

  clearInput() {
    this.inputForm.get('input').setValue(0);
  }

  tab() {
    this.tabOutput.emit();
  }

  onFocusInput(event: FocusEvent) {
    this.disableKeyListener = true; // Disable global listener on focus
    const target = event.target as HTMLInputElement;
    target.select();
  }

  onBlurInput() {
    this.disableKeyListener = false; // Re-enable global listener on blur
  }
}
