<div class="container-fluid full-height p-0">
  <div class="row full-height m-0">
    <div class="col-8 p-0">
      <div class="row">
        <div class="col-3 mb-2">
          <div mdbRipple class="pt-2 pb-2 d-flex justify-content-center align-items-center bg-success" (click)="addProduct(100)">
            <div>Payment Approved</div>
          </div>
        </div>
        <div class="col-3">
          <div mdbRipple class="pt-2 pb-2 d-flex justify-content-center align-items-center bg-danger" (click)="addProduct(101)">
            <div>Call Issuer</div>
          </div>
        </div>
        <div class="col-3">
          <div mdbRipple class="pt-2 pb-2 d-flex justify-content-center align-items-center" (click)="addProduct(102)">
            <div>Require Pin</div>
          </div>
        </div>
        <div class="col-3">
          <div mdbRipple class="pt-2 pb-2 d-flex justify-content-center align-items-center" (click)="addProduct(103)">
            <div>Require Pin</div>
          </div>
        </div>
        <div class="col-3">
          <div mdbRipple class="pt-2 pb-2 d-flex justify-content-center align-items-center" (click)="addProduct(105)">
            <div>Generic Decline</div>
          </div>
        </div>
        <div class="col-3">
          <div mdbRipple class="pt-2 pb-2 d-flex justify-content-center align-items-center" (click)="addProduct(155)">
            <div>Incorrect Pin</div>
          </div>
        </div>
        <div class="col-3">
          <div mdbRipple class="pt-2 pb-2 d-flex justify-content-center align-items-center" (click)="addProduct(165)">
            <div>Withdrawl Count Limit</div>
          </div>
        </div>
        <div class="col-3">
          <div mdbRipple class="pt-2 pb-2 d-flex justify-content-center align-items-center" (click)="addProduct(107)">
            <div>Pin Retry Exceeded</div>
          </div>
        </div>
      </div>

    </div>
    <div class="col-4 p-0 d-flex flex-column">
      <div class="transaction-details">

        <ng-container *ngIf="lineItems.length>0; else noLineItems">
          <div *ngFor="let item of lineItems">
            <div class="d-flex">
              <div class="p-2 flex-fill">
                {{item.description}}
              </div>
              <div class="p-2 flex-fill text-end">
                {{(item.amount/100) | currency}}
              </div>
            </div>
          </div>
          <div>
            <div class="d-flex">
              <div class="ps-5 pe-2 ps-5 ms-5 flex-fill text-start">
                Subtotal:
              </div>
              <div class="pe-2 flex-fill text-end">
                {{(subtotal/100) | currency}}
              </div>
            </div>
            <div class="d-flex">
              <div class="ps-5 pe-2 ps-5 ms-5 flex-fill text-start">
                Tax:
              </div>
              <div class="pe-2 flex-fill text-end">
                {{0 | currency}}
              </div>
            </div>
            <div class="d-flex">
              <div class="ps-5 pe-2 ps-5 ms-5 flex-fill text-start">
                Amount Due:
              </div>
              <div class="pe-2 flex-fill text-end">
                {{(subtotal/100) | currency}}
              </div>
            </div>
          </div>
        </ng-container>
        <ng-template #noLineItems>
          <div class="text-center mt-5 mb-4">
            <i class="fas fas fa-shopping-cart fa-3x"></i>
            <h5 class="mt-3">Cart Empty</h5>
          </div>
        </ng-template>
        <div *ngIf="receiptPrinterStatus && receiptPrinterStatus.CompulsionSwitch" class="text-center bg-danger text-light pt-1 pb-1">
          <h5>Cash Drawer Open</h5>
        </div>
        <div *ngIf="receiptPrinterStatus && receiptPrinterStatus.PaperEmpty" class="text-center bg-danger text-light pt-1 pb-1">
          <h5>Paper Empty</h5>
        </div>
        <div *ngIf="receiptPrinterStatus && receiptPrinterStatus.PaperLow && !receiptPrinterStatus.PaperEmpty" class="text-center bg-danger text-light pt-1 pb-1">
          <h5>Paper Low</h5>
        </div>
        <div *ngIf="receiptPrinterStatus && receiptPrinterStatus.CoverOpen" class="text-center bg-danger text-light pt-1 pb-1">
          <h5>Cover Open</h5>
        </div>

      </div>
      <div class="control-buttons">
        <app-pos-controls [communityDetails]="communityDetails" [terminalId]="terminalId" [lineItems]="lineItems" (emptyCart)="emptyCart($event)"></app-pos-controls>
      </div>
    </div>
  </div>
</div>