<form [formGroup]="reconciliationForm">
  <div class="container-fluid full-height p-0">
    <div class="row full-height m-0">
      <!-- Main Section -->
      <div class="col-8 p-2">
        <div class="row mb-5">
          <ng-container *ngFor="let group of groupedDenominations; index as i">
            <div class="col-12 mb-3">
              <h5 *ngIf="i===0">Enter Quantities</h5>
              <h6>{{ group.type | titlecase }}s</h6>
            </div>
            <div class="col-3 mb-2" *ngFor="let denomination of group.denominations">
              <div mdbRipple (click)="makeSelection(denomination.name, denomination.namePlural)"
                class="pt-2 pb-2 d-flex justify-content-center align-items-center card card-border"
                [ngClass]="{ 'bg-primary': denomination.name === selectedItem }">
                <h5>{{reconciliationForm.controls[denomination.name].value | number}}x</h5>
                {{ denomination.name }}
              </div>
            </div>
          </ng-container>
        </div>
        <div class="row mb-2">
          <div class="col-12 mb-3">
            <h5>Select Details</h5>
            Type of Reconciliation<br>
            Notes<br>
            <div class="text-center">
              <button (click)="submit()" [disabled]="reconciliationForm.invalid||submitting" type="button"
              class="btn btn-primary btn-rounded text-capitalize">
              <span *ngIf="submitting"><span class="spinner-border spinner-border-sm me-2" role="status"
                  aria-hidden="true"></span> Submitting Reconciliation</span>
              <span *ngIf="!submitting">Submit Reconciliation</span>
            </button>

            </div>
          </div>
        </div>
      </div>

      <!-- Sidebar -->
      <div class="col-4 p-0 d-flex flex-column">
        <div class="transaction-details p-2">
          <ng-container>
            <ng-container *ngFor="let group of groupedDenominations">
              <ng-container *ngIf="hasNonZeroValuesInGroup(group); else notInGroup">
                <h6>{{ group.type | titlecase }}s</h6>
                <ng-container *ngFor="let denomination of group.denominations">
                  <div *ngIf="reconciliationForm.controls[denomination.name].value > 0">
                    <div class="d-flex">
                      <div class="p-2 pt-0 flex-fill">
                        {{ reconciliationForm.controls[denomination.name].value | number }}x

                        <ng-container
                          *ngIf="reconciliationForm.controls[denomination.name].value === 1; else denominationPlural">
                          {{ denomination.name }}
                        </ng-container>
                        <ng-template #denominationPlural>
                          {{ denomination.namePlural || denomination.name }}
                        </ng-template>
                      </div>
                      <div class="p-2 flex-fill text-end">
                        {{((reconciliationForm.controls[denomination.name].value * denomination.value)/100) | currency}}
                      </div>
                    </div>
                  </div>
                </ng-container>
              </ng-container>
              <ng-template #notInGroup>
                <h6>{{ group.type | titlecase }}s</h6>
                <div class="d-flex">
                  <div class="p-2 pt-0 flex-fill">
                    No quantities selected
                    </div>
                    </div>
              </ng-template>
            </ng-container>
            <h4 class="text-end">Total: {{ (totalAmount / 100) | currency }}</h4>
          </ng-container>

          <ng-template #emptyState>
            <p class="text-muted">Start filling out the form to see the breakdown here.</p>
          </ng-template>
        </div>

        <div class="control-buttons">
          <app-pos-keypad *ngIf="selectedItem" [value]="selectedItemAmount" [label]="selectedLabel"
            (inputChange)="inputChange($event)" (tabOutput)="tab()"></app-pos-keypad>
        </div>
      </div>
    </div>
  </div>
</form>