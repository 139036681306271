<div class="modal-header">
    <h5 class="modal-title" id="exampleModalLabel">Edit Card Reader</h5>
    <button type="button" class="btn-close" aria-label="Close" (click)="modalRef.close()"></button>
  </div>
  <div class="modal-body">
    <form [formGroup]="eventForm">
      <div class="input-group mt-2">
        <div class="input-container">
          <mdb-form-control>
            <input mdbInput [mdbValidate] type="text" formControlName="label" id="label"
              class="form-control form-control-lg" [validateSuccess]="false" placeholder="Label" maxLength="50"/>
            <label mdbLabel class="form-label" for="label">Label</label>
            <div class="form-helper">
              <div class="form-counter">{{ eventForm.controls.label.value.length }} / 50</div>
              <div *ngIf="eventForm.controls.label.value.length < 3" class="form-counter">Minimum 3 characters required
              </div>
              <div *ngIf="eventForm.controls.label.value.length === 50" class="form-counter">Maximum 50 characters allowed
              </div>
            </div>
          </mdb-form-control>
        </div>
        <span class="input-group-text border-0"><app-validation-icon [inputValid]="eventForm.controls.label.invalid"></app-validation-icon>
          <i class="far fa-question-circle" mdbTooltip="Tooltip" [html]="true" placement="right"></i></span>
      </div>
      <br class="noselect">
    </form>

  </div>
  <div class="modal-footer">
    <button (click)="submit()" [disabled]="eventForm.invalid||savingEvent" type="button" class="btn btn-primary btn-rounded">
      <span *ngIf="savingEvent"><span class="spinner-border spinner-border-sm me-2" role="status"aria-hidden="true"></span> Submitting</span>
      <span *ngIf="!savingEvent">Submit</span>
    </button>
  </div>
  