<div class="p-5 text-center bg-gradient-animation bg-image" style="height: 200px;">
  <div class="mask">
    <div class="d-flex justify-content-center align-items-center h-100">
      <div class="text-white">
        <h4 class="display-4 mb-3">{{headerText}}</h4>
      </div>
    </div>
  </div>
</div>
<div class="row mb-8" style="min-height: 380px;">
  <div class="col-md-8 offset-md-2">
    <div class="container-fluid card card-body" style="word-wrap: break-word;">
      <ng-container *ngIf="!error; else showError">
        <ng-container *ngIf="token; else noLti">
          <ng-container *ngIf="loading; else notLoading">
            <app-loading></app-loading>
          </ng-container>
          <ng-template #notLoading>
            <ng-container *ngIf="userLoggedIn; else noEOUserLoggedIn">
              <ng-container *ngIf="res.resourceConfigured; else resourceNotConfigured">
                <ng-container *ngIf="res.isTeacher; else notTeacher">
                  <ng-container *ngIf="!editRegistration; else showRegistration">
                    <div class="pointer text-end text-primary" (click)="editRegistration = true">Edit Resource</div>
                    <div [ngSwitch]="res.resourceDetails.type">
                      <div *ngSwitchCase="'contacts'">
                        <h4>Contacts</h4>
                        <app-edit-lti-contacts></app-edit-lti-contacts>
                      </div>
                      <div *ngSwitchCase="'forms'">
                        <h4>Forms</h4>
                        <app-edit-lti-forms></app-edit-lti-forms>
                      </div>
                      <div *ngSwitchCase="'fees'">
                        <h4>Fees</h4>
                        <app-edit-lti-fees></app-edit-lti-fees>
                      </div>
                      <div *ngSwitchCase="'inventory'">
                        <h4>Inventory</h4>
                        <app-edit-lti-inventory></app-edit-lti-inventory>
                      </div>
                    </div>
                  </ng-container>
                  <ng-template #showRegistration>
                    <div class="pointer text-end text-primary" (click)="editRegistration = false">Go Back</div>
                    <h5>Let's edit this page!</h5>
                    <app-create-lti-registrations [resourceDetails]="res.resourceDetails"
                      (output)="saveRegistrationConfig($event)"></app-create-lti-registrations>
                  </ng-template>
                </ng-container>
                <ng-template #notTeacher>
                  <ng-container *ngIf="res.isStudent">
                    <div [ngSwitch]="res.resourceDetails.type">
                      <div *ngSwitchCase="'contacts'">
                        <h4>Contacts</h4>
                        <app-view-lti-contacts></app-view-lti-contacts>
                      </div>
                      <div *ngSwitchCase="'forms'">
                        <h4>Forms</h4>
                        <app-view-lti-forms></app-view-lti-forms>
                      </div>
                      <div *ngSwitchCase="'fees'">
                        <h4>Fees</h4>
                        <app-view-lti-fees></app-view-lti-fees>
                      </div>
                      <div *ngSwitchCase="'inventory'">
                        <h4>Inventory</h4>
                        <app-view-lti-inventory></app-view-lti-inventory>
                      </div>
                    </div>
                  </ng-container>
                </ng-template>
              </ng-container>
              <ng-template #resourceNotConfigured>
                <ng-container *ngIf="res.isTeacher; else notTeacher">
                  <h5>Let's finish setting up this page!</h5>
                  <app-create-lti-registrations
                    (output)="saveRegistrationConfig($event)"></app-create-lti-registrations>
                </ng-container>
                <ng-template #notTeacher>
                  <div class="text-center">
                    <i class="fas fa-5x fa-chalkboard-teacher mb-4"></i><br>
                    <p>Ask your instructor to finish configuring this page.</p>
                  </div>
                </ng-template>
              </ng-template>
            </ng-container>
            <ng-template #noEOUserLoggedIn>
              <div class="text-center">
                <i class="fas fa-5x fa-chalkboard-teacher mb-4"></i><br>
                <p>Authentication Failed. Ask your systems administrator for help configuring the Event Odyssey LTI
                  plugin. </p>
              </div>
            </ng-template>
          </ng-template>

        </ng-container>
        <ng-template #noLti>
          <app-https-error [error]="'Unable to parse Canvas/LTI user information.'"></app-https-error>
        </ng-template>

      </ng-container>
      <ng-template #showError>
        <app-https-error [error]="error"></app-https-error>
      </ng-template>
    </div>
  </div>
</div>
<div>
  <app-lti-footer></app-lti-footer>
</div>