import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-create-lti-registrations',
  templateUrl: './create-lti-registrations.component.html',
  styleUrl: './create-lti-registrations.component.scss'
})
export class CreateLtiRegistrationsComponent implements OnInit {
  inviteForm: FormGroup;
  @Output() output = new EventEmitter<any>();
  @Input() resourceDetails
  resourceTypes = [
    {
      name: 'Linked Accounts',
      id: 'contacts',
      description: 'Manage student linked accounts (parents/guardians)',
      icon: 'fas fa-user-check'
    },
    {
      name: 'Forms',
      id: 'forms',
      description: 'Manage forms and document upload requirements',
      icon: 'fas fa-file-contract fa-fw'
    },
    {
      name: 'Fee Management',
      id: 'fees',
      description: 'Manage fees',
      icon: 'fas fa-file-invoice-dollar fa-fw'
    },
    {
      name: 'Inventory',
      id: 'inventory',
      description: 'View inventory assignments',
      icon: 'fas fa-file-invoice fa-fw'
    },
  ]
  displaySelectDropdown: boolean = false
  constructor() {

  }

  ngOnInit(): void {
    if(this.resourceDetails) {
      this.inviteForm = new FormGroup({
        name: new FormControl(this.resourceDetails.headerName || '', Validators.compose([Validators.minLength(3), Validators.required])),
        type: new FormControl(this.resourceDetails.type || '', Validators.compose([Validators.minLength(3), Validators.required])),
      });
    } else {
      this.inviteForm = new FormGroup({
        name: new FormControl('', Validators.compose([Validators.minLength(3), Validators.required])),
        type: new FormControl('', Validators.compose([Validators.minLength(3), Validators.required])),
      });
    }

  }

  submit() {
    const type = this.inviteForm.controls.type.value
    const name = this.inviteForm.controls.name.value
    this.output.emit({ 
      type: type,
      name: name
     })

  }

  calculateHeight(): string {
    const baseHeight = 230;
    const minimumHeight = 60;
    const heights = [
      minimumHeight,       // 0 items
      baseHeight / 4,     // 1 item
      baseHeight / 2,     // 2 items
      (3 * baseHeight) / 4,  // 3 items
      baseHeight         // 4 items and above
    ];
    return Math.max(minimumHeight, heights[this.resourceTypes.length] || baseHeight) + 'px';
  }


}
