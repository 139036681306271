<form [formGroup]="inviteForm">
    <div class="input-group mt-2">
        <div class="input-container">
            <mdb-form-control>
                <input mdbInput [mdbValidate] type="text" formControlName="name" id="name"
                    class="form-control form-control-lg" [validateSuccess]="false" placeholder="Name" maxLength="50" />
                <label mdbLabel class="form-label" for="name">Name</label>
                <div class="form-helper">
                    <div class="form-counter">{{ inviteForm.controls.name.value.length }} / 50</div>
                    <div *ngIf="inviteForm.controls.name.value.length < 3" class="form-counter">Minimum 3 characters
                        required
                    </div>
                    <div *ngIf="inviteForm.controls.name.value.length === 50" class="form-counter">Maximum 50 characters
                        allowed
                    </div>
                </div>
            </mdb-form-control>
        </div>
        <span class="input-group-text border-0"><app-validation-icon
                [inputValid]="inviteForm.controls.name.invalid"></app-validation-icon>
        </span>
    </div>
    <br class="noselect">
    <br *ngIf="inviteForm.controls.name.value.length < 3 || inviteForm.controls.name.value.length === 50"
        class="noselect">


    <div class="input-group mt-2">
        <div #group class="input-container">
            <mdb-form-control>
                <ng-select [virtualScroll]="false" mdbInput formControlName="type" (open)="displaySelectDropdown=true"
                    (close)="displaySelectDropdown=false" [items]="resourceTypes" [multiple]="false" placeholder="Type"
                    bindLabel="name" bindValue="id" appearance="outline" [closeOnSelect]="true" [clearable]="true"
                    [searchable]="false" notFoundText="No Community Types found" autocomplete="off" required>
                    <ng-template ng-option-tmp let-item="item" let-index="index">
                        <div [ngOptionHighlight]="search" class="lh-1 pt-1 pb-1">
                            <div class="d-flex mt-0 pt-0 mb-0 pb-0">
                                <div class="p-2">
                                    <i class="{{item.icon}} fa-2x mb-3"></i>
                                </div>
                                <div class="flex-grow-1 mt-0 pt-0 mb-0 pb-0">
                                    <h6 class="mt-2 mb-0 pb-0">{{item.name}}</h6>
                                    <small class="mt-0 pt-0 mb-0 pb-0">{{item.description}}</small>
                                </div>
                              </div>

                        </div>
                    </ng-template>
                </ng-select>
            </mdb-form-control>
        </div>
        <span class="input-group-text border-0"><app-validation-icon
                [inputValid]="inviteForm.controls.type.invalid"></app-validation-icon></span>
    </div>
    <div *ngIf="displaySelectDropdown" [style.height]="calculateHeight()"></div>



    <br>
    <div class="text-center">
        <button (click)="submit()" [disabled]="inviteForm.invalid" type="button"
            class="btn btn-primary btn-rounded">Submit </button>
    </div>
</form>